<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="8"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('apps.masterDataProductCategory.singular.code')"
                  label-for="code"
                >
                  <b-form-input
                    id="category-code"
                    size="sm"
                    v-model="form.code"
                    :placeholder="$t('apps.masterDataProductCategory.placeholder.code')"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataProductCategory.singular.name')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="category-name"
                      size="sm"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataProductCategory.placeholder.name') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.type')"
                  label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="form.type"
                      :options="LOV.types"
                      :reduce="types => types.code"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      disabled
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('globalSingular.type')  : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataWorkUnit.singular.workUnitHeadUser')"
                  label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="type"
                    rules="required"
                  >
                    <v-select
                      id="head-user"
                      v-model="form.headUserId"
                      :options="LOV.users"
                      :reduce="field => field.user.id"
                      label="remark"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    >
                      <template v-slot:option="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.groupCode')"
                  label-for="code"
                >
                  <b-form-input
                    id="category-code"
                    size="sm"
                    v-model="form.groupCode"
                    :placeholder="$t('globalSingular.groupCode')"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('globalSingular.groupName')"
                  label-for="group-name"
                >
                  <b-form-input
                    id="group-name"
                    size="sm"
                    v-model="form.groupName"
                    :placeholder="!actions.isPreview ? $t('globalSingular.groupName') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.groupType')"
                  label-for="group-type">
                  <v-select
                    id="group-type"
                    v-model="form.groupType"
                    :options="LOV.types"
                    :reduce="types => types.code"
                    label="label"
                    disabled
                    class="select-size-sm"
                    :placeholder="!actions.isPreview ? $t('globalSingular.groupType')  : null"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataWorkUnit.singular.groupHeadUser')"
                  label-for="group-head">
                  <v-select
                    id="head-user"
                    v-model="form.groupHeadUserId"
                    :options="LOV.users"
                    :reduce="field => field.user.id"
                    label="remark"
                    :disabled="actions.isPreview"
                  >
                    <template v-slot:option="option">
                      {{ option.user.user_name }} - {{ option.user.user_email }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.user.user_name }} - {{ option.user.user_email }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-work-unit-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  // BFormTextarea,
  BButton
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    // BFormTextarea,
    BButton,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/work-unit', localeContextPath: 'apps.masterDataWorkUnit.moduleName', redirectPathName: 'apps-master-data-work-unit' })

    const LOV = ref({
      types: [
        {
          label: 'Division',
          code: 'DIVISION'
        },
        {
          label: 'Department',
          code: 'DEPARTMENT'
        }
      ],
      users: []
    })

    const form = ref({
      code: '',
      name: '',
      type: 'DEPARTMENT',
      headUserId: null,
      groupCode: '',
      groupName: '',
      groupType: 'DIVISION',
      groupHeadUserId: null
    })

    const getCompanyUsers = async () => {
      LOV.value.users = await get({ url: 'value/company-users' })
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].code
      form.value.name = data[0].name
      form.value.type = data[0].type
      form.value.headUserId = data[0].head_user_id
      form.value.groupName = data[0].group_name
      form.value.groupType = data[0].group_type
      form.value.groupHeadUserId = data[0].group_head_user_id
    }

    onMounted(() => {
      getCompanyUsers()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
    })

    return {
      LOV,
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
